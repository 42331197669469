
import {
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonCheckbox,
  IonText,
} from '@ionic/vue';
import { defineComponent, inject, ref, Ref, watch } from 'vue';
import axios from 'axios';
import PasswordMeter from 'vue-simple-password-meter';
import MessageBox from '@/components/MessageBox.vue';
import LoadingButton from '@/components/LoadingButton.vue';
import { useRouter } from 'vue-router';
import { throttle } from 'lodash';

export default defineComponent({
  name: 'Signup',
  components: {
    MessageBox,
    LoadingButton,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonCheckbox,
    IonText,
    PasswordMeter,
  },
  setup() {
    const router = useRouter();
    // Email validation regex taken from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const usernamePattern = /^[a-zA-Z0-9 ]*$/;
    const setUsername: (name: string) => void = inject('setUsername', () => undefined);
    const setUserEmail: (email: string) => void = inject('setUserEmail', () => undefined);
    const setConfirmationNeeded: (confirmationNeeded: boolean) => void = inject(
      'setConfirmationNeeded',
      () => undefined,
    );
    const emailText = ref('');
    const usernameText = ref('');
    const passwordText = ref('');
    const passwordStrength = ref('');
    const confirmPasswordText = ref('');
    const termsCheckbox = ref(false);
    const errorMsg: Ref<typeof MessageBox | null> = ref(null);
    const signupButton: Ref<typeof LoadingButton | null> = ref(null);

    const isValidEmail = (email: string): boolean => {
      return emailPattern.test(email.toLowerCase());
    };

    const isValidPassword = (password: string): boolean => {
      return password.length >= 8;
    };

    const isValidUsername = (username: string): boolean => {
      return username.length >= 5 && username.length <= 20 && usernamePattern.test(username);
    };

    const onSubmit = throttle(async (): Promise<boolean> => {
      errorMsg.value?.close();

      emailText.value = emailText.value.trim();
      usernameText.value = usernameText.value.trim();

      // Invalid credentials
      if (!isValidEmail(emailText.value)) {
        errorMsg.value?.showMsg('Invalid email');
        return false;
      }
      if (!isValidUsername(usernameText.value)) {
        errorMsg.value?.showMsg(
          'Username has to be between 5 to 20 characters and contains only letters, numbers, and spaces',
        );
        return false;
      }
      if (!isValidPassword(passwordText.value)) {
        errorMsg.value?.showMsg('Password has to be at least 8 characters');
        return false;
      }
      if (passwordText.value !== confirmPasswordText.value) {
        errorMsg.value?.showMsg('Passwords do not match');
        return false;
      }
      if (!termsCheckbox.value) {
        errorMsg.value?.showMsg('Please agree to the terms and conditions');
        return false;
      }

      // Valid credentials
      signupButton.value?.setIsLoading(true);
      setUsername(usernameText.value);
      setUserEmail(emailText.value);
      try {
        const response = await axios.post(
          process.env.VUE_APP_USER_ENDPOINT_URL + '/v1/user/signup',
          {
            name: usernameText.value,
            email: emailText.value,
            password: passwordText.value,
          },
        );
        if (response.data.Message === 'Sign up success') {
          errorMsg.value?.close();
          setConfirmationNeeded(true);
          router.push({ name: 'Confirm' });
        } else {
          errorMsg.value?.showMsg('Unable to sign up: ' + response.data.Message);
        }
      } catch (error:any) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.data.Message === 'UsernameExistsException') {
            errorMsg.value?.showMsg('Username exists!');
          } else {
            console.error(error.response.data);
          }
        } else if (error.request) {
          errorMsg.value?.showMsg('Unknown error occured');
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMsg.value?.showMsg('Error: ' + error.message);
        }
      } finally {
        signupButton.value?.setIsLoading(false);
      }
      return true;
    }, 1000);

    const onPasswordScore = (payload: { score: number; strength: string }): void => {
      if (passwordText.value.length === 0) {
        passwordStrength.value = '';
      } else {
        passwordStrength.value =
          payload.strength.charAt(0).toUpperCase() + payload.strength.slice(1);
      }
    };

    watch(passwordText, () => {
      if (passwordText.value.length == 0) {
        passwordStrength.value = '';
      }
    });

    const clickSignupButton = (): void => {
      document.getElementById('signupButton')?.click();
    };

    const clickSendButton = (): void => {
      document.getElementById('sendButton')?.click();
    };

    return {
      onSubmit,
      signupButton,
      clickSignupButton,
      clickSendButton,
      emailText,
      usernameText,
      passwordText,
      confirmPasswordText,
      onPasswordScore,
      passwordStrength,
      errorMsg,
      termsCheckbox,
    };
  },
});
